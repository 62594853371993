import { SVGProps, Ref, forwardRef } from 'react'

const SvgCheckBold = (props: SVGProps<SVGSVGElement>, ref: Ref<SVGSVGElement>) => (
  <svg
    width="1.5rem"
    height="1.5rem"
    fill="none"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    ref={ref}
    {...props}
  >
    <circle cx={12} cy={12} r={10.875} stroke="currentColor" strokeWidth={2.25} />
    <path
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2.25}
      d="m8.398 11.7 2.592 2.7 4.608-4.8"
    />
  </svg>
)

const ForwardRef = forwardRef(SvgCheckBold)
export default ForwardRef
